(function($) {
  "use strict"; // Start of use strict

  // Smooth scrolling using jQuery easing
  $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: (target.offset().top)
        }, 150, "easeInOutExpo");
        return false;
      }
    }
  });

  // Closes responsive menu when a scroll trigger link is clicked
  $('.js-scroll-trigger').click(function() {
    $('.navbar-collapse').collapse('hide');
  });

  // Activate scrollspy to add active class to navbar items on scroll
  $('body').scrollspy({
    target: '#sideNav'
  });

})(jQuery); // End of use strict


// console.log("El código se está ejecutando");

$(document).ready(function() {
  $('#entidades-link').click(function() {
    // console.log("click entidades");
    $('#convenios-container, #usuarios-container').hide();
    $('#entidades-container').load('admin/vistas/dt_entidades.php').fadeIn("slow");
  });
  $('#convenios-link').click(function() {
    // console.log("click convenios");
    $('#entidades-container, #usuarios-container').hide();
    $('#convenios-container').load('admin/vistas/dt_convenios.php').fadeIn("slow");
  });
  $('#usuarios-link').click(function() {
    // console.log("click usuarios");
    $('#entidades-container, #convenios-container').hide();
    $('#usuarios-container').load('admin/vistas/dt_usuarios.php').fadeIn("slow");
  });
});



